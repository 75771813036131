import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
// import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import Earn from './Earn'
import Manage from './Earn/Manage'
// import MigrateV2 from './MigrateV2'
// import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
// import Pool from './Pool'
// import PoolV2 from './Pool/v2'
// import PoolFinder from './PoolFinder'
// import RemoveLiquidity from './RemoveLiquidity'
// import RemoveLiquidityV3 from './RemoveLiquidity/V3'
// import Swap from './Swap'
// import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Vote from './Vote'
import VotePage from './Vote/VotePage'
// import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
// import { PositionPage } from './Pool/PositionPage'
// import AddLiquidity from './AddLiquidity'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import CreateProposal from './CreateProposal'
// import HelloWorld from './Demo/HelloWorld'
import Dashboard from './Dashboard'
import Explore from './Explore'
import TanTable from './Demo/TanTable'
import DisplayPricesFromUniswapSubgraph from './Demo/DisplayPricesFromUniswapSubgraph'
import ReduxCounterExample from './Demo/ReduxCounterExample'
import MaterialUITable from './Demo/MaterialUITable'
import Web3ReactExample from './Demo/Web3ReactExample'
import ShowPandiFiContracts from './Demo/ShowPandiFiContracts'
import Metatable from './Metatable'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6rem 16px 16px 16px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          <TopLevelModals />
          <Web3ReactManager>
            <Switch>
              {/* <Route exact strict path="/send" component={RedirectPathToSwapOnly} /> */}
              {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} /> */}
              {/* <Route exact strict path="/swap" component={Swap} /> */}

              {/* <Route exact strict path="/pool/v2/find" component={PoolFinder} />
              <Route exact strict path="/pool/v2" component={PoolV2} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/pool/:tokenId" component={PositionPage} /> */}

              {/* <Route exact strict path="/add/v2/:currencyIdA?/:currencyIdB?" component={RedirectDuplicateTokenIdsV2} /> */}
              {/* <Route
                exact
                strict
                path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                component={RedirectDuplicateTokenIds}
              /> */}

              {/* <Route
                exact
                strict
                path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                component={AddLiquidity}
              /> */}

              {/* <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} /> */}

              {/* <Route exact strict path="/migrate/v2" component={MigrateV2} />
              <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} /> */}

              <Route exact strict path="/dashboard" component={Dashboard} />
              <Route exact strict path="/explore" component={TanTable} />
              <Route exact strict path="/metatable" component={Metatable} />
              <Route exact strict path="/dehomogenize" component={ReduxCounterExample} />
              <Route exact strict path="/prices" component={DisplayPricesFromUniswapSubgraph} />
              {/* <Route exact strict path="/table" component={MaterialUITable} /> */}
              {/* <Route exact strict path="/pandifi" component={ShowPandiFiContracts} /> */}

              <Route exact strict path="/stake" component={Earn} />
              <Route exact strict path="/stake/:currencyIdA/:currencyIdB" component={Manage} />

              <Route exact strict path="/vote" component={Vote} />
              <Route exact strict path="/vote/:governorIndex/:id" component={VotePage} />
              <Route exact strict path="/create-proposal" component={CreateProposal} />
              {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}

              <Route exact strict path="/tantable" component={TanTable} />

              <Route component={Web3ReactExample} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </ErrorBoundary>
  )
}
