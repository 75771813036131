//@ts-nocheck
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { useMemo } from 'react'
import styled from 'styled-components'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination,
  useResizeColumns,
  useFlexLayout,
} from 'react-table'
import { Checkbox } from './Checkbox'
import { GlobalFilter } from './GlobalFilter'
import { ColumnFilter } from './ColumnFilter'
import { NumberRangeColumnFilter } from './NumberRangeColumnFilter'

const Button = styled.button`
  color: rgb(33, 114, 229);
  background: none;
  border: 0;
`
const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      // justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
  },
]

export default function MetaTable({ hidden, columns, data }) {
  console.log('hidden: ', hidden)
  console.log('columns: ', columns)
  console.log('data: ', data)

  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  )
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hidden,
      },
    },
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          disableResizing: true,
          minWidth: 35,
          width: 35,
          maxWidth: 35,
          Header: function headerFunc({ getToggleAllRowsSelectedProps }) {
            return <Checkbox {...getToggleAllRowsSelectedProps()} />
          },
          Cell: function cellFunc({ row }) {
            return <Checkbox {...row.getToggleRowSelectedProps()} />
          },
        },
        ...columns,
      ])
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        const selectionGroupHeader = headerGroups[0].headers[0]
        selectionGroupHeader.canResize = false
      })
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    preGlobalFilteredRows,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    setHiddenColumns,
    state,
  } = tableInstance
  const { globalFilter, pageIndex, pageSize } = state

  return (
    <div>
      <h1>Table</h1>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(headerProps)}>
                    {
                      // Render the header
                      column.render('Header')
                    }
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    <div>{column.canFilter ? column.render('Filter') : null} </div>
                  </th>
                ))
              }
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps(cellProps)}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="10">
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                ←←
              </Button>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                ←
              </Button>
              Page <strong>{pageIndex + 1} </strong>of <strong>{pageOptions.length}</strong>{' '}
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                →
              </Button>
              <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                →→
              </Button>
              <br></br>
              <span>
                Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(pageNumber)
                  }}
                  style={{ width: '50px' }}
                />
              </span>
              <br></br>
              <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        </tfoot>
      </table>
      <div>
        <pre>
          <code>
            {JSON.stringify(
              {
                selectedFlatRows: selectedFlatRows.map((row) => row.original),
              },
              null,
              2
            )}
          </code>
        </pre>
      </div>
    </div>
  )
}
