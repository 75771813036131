/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { useMemo } from 'react'
import { ColumnFilter } from './ColumnFilter'
import { NumberRangeColumnFilter } from './NumberRangeColumnFilter'

const href = 'https://rinkeby.etherscan.io/address/'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

// function toCurrency(number: number): string {
//   const number = parseFloat(number)
//   return number.toLocaleString('USD')
// }

export const tableColumns = [
  {
    Header: 'token ID',
    accessor: 'tokenId',
    Filter: ColumnFilter,
    disableFilters: true,
    width: 60,
  },
  {
    Header: 'UPB',
    accessor: 'UPB',
    Filter: NumberRangeColumnFilter,
    width: 180,
    // @ts-ignore
    Cell: (props) => <div>{formatter.format(props.value)}</div>,
  },
  {
    Header: 'Other Data',
    accessor: 'otherData',
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: 'Channel',
    accessor: 'channel',
    Filter: ColumnFilter,
    disableFilters: false,
  },
  {
    Header: 'Best LP',
    accessor: 'bestLP',
    width: 100,
  },
  {
    Header: 'Best LP Price',
    accessor: 'bestLPPrice',
    Filter: NumberRangeColumnFilter,
    width: 180,
  },
  {
    Header: 'Current Owner',
    accessor: 'currentOwner',
    // @ts-ignore
    Cell: (props) => (
      <a href={href + props.value} target="_blank" rel="noreferrer">
        {props.value?.toString()?.substring(0, 6).concat('...').concat(props.value?.slice(-4))}
      </a>
    ),
  },
  {
    Header: 'Owner Is Homogenizer',
    accessor: 'ownerIsHomogenizer',
    width: 120,
  },
  {
    Header: 'Owned By ActiveAccount',
    accessor: 'ownedByActiveAccount',
  },
  {
    Header: 'UPB Of HomogenizerOwned',
    accessor: 'UPBOfHomogenizerOwned',
  },
]
