import { useEffect, useMemo, useState } from 'react'
import { useActiveWeb3React } from './web3'
import { useDigitizer } from './useContract'
import { BigNumber, ethers } from 'ethers'
import { Address } from 'cluster'
import { useTotalSupply } from './useTotalSupply'
import { stringify } from 'qs'
import { formatMs } from '@material-ui/core'

export function useDigitizerTotalSupply(): any {
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState(() => 0)

  useEffect(() => {
    digitizer?.totalSupply().then((totalSupply) => setResult(totalSupply?.toNumber()))
  }, [account, chainId, library])

  return result
}

export function useDigitizerBalanceOfCurrentUser(): any {
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState(() => 0)

  useEffect(() => {
    digitizer?.balanceOf(account!).then((userBalance) => setResult(userBalance?.toNumber()))
  }, [account, chainId, library])

  return result
}

export function toDecimal(bigNumber: BigNumber, decimalPlaces = 2): number {
  const regularNumber = parseFloat(ethers.utils.formatUnits(bigNumber))
  return Math.round(regularNumber * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

// function toDecimalOnJSON(data: any) {
//   Object.keys(data).forEach((item, index) => {
//     if (typeof data[item] == 'object') {
//       console.log(1)
//       // @ts-ignore
//       data[item] = data[item].map((x) => (typeof x == 'BigNumber' ? toDecimal(x) : x))
//     } else {
//       console.log(0)
//     }
//   })
//   // @ts-ignore
//   // return data.map((x) => (typeof x == 'BigNumber' ? 1 : 0))
// }

// todo: what's causing all the typescript errors
export function useDigitizerTokensOfCurrentUser(): any {
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState<any>([])

  console.log('library: ', library)

  // This should probably be converted to useMemo
  useEffect(() => {
    ;(async () => {
      let tokensOfCurrentUser: BigNumber[] | number[] | undefined = await digitizer?.tokensOf(account!)
      tokensOfCurrentUser = tokensOfCurrentUser?.map((x) => x.toNumber())
      // @ts-ignore
      // const thedata = await digitizer?.getTokenData(1)
      // @ts-ignore
      // console.log('json: ', thedata.original.creditScore)
      // toDecimalOnJSON(thedata)
      const data = []
      // @ts-ignore
      for (let index = 0; index < tokensOfCurrentUser?.length; index++) {
        // @ts-ignore
        const tokenData = await digitizer?.getTokenData(tokensOfCurrentUser[index])
        // console.log(toDecimal(tokenData['UPBAtIssuance']))
        // @ts-ignore
        data.push({
          // @ts-ignore
          tokenId: tokensOfCurrentUser[index],
          // @ts-ignore
          UPBAtIssuance: toDecimal(tokenData.UPBAtIssuance),
          // @ts-ignore
          accumulatedCashFlow: toDecimal(tokenData.accumulatedCashFlow),
          // @ts-ignore
          channel: tokenData?.original.channel,
          // @ts-ignore
          noteRate: toDecimal(tokenData?.original.noteRate),
          // @ts-ignore
          creditScore: tokenData?.original.creditScore,
        })
      }
      setResult(data)
    })()
  }, [account, chainId, library])

  return result
}

// This hook pull in
// 1) All data from the blockchain and
// 2) Actions i.e., buttons
export function useDigitizerCreateMetaTableData(): any {
  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // })
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState<any>([])
  useEffect(() => {
    ;(async () => {
      const totalSupply = Number(await digitizer?.totalSupply())
      const tokenAllIdArray = Array.from(Array(totalSupply).keys()).map((i) => i + 1)
      const data = []
      for (let index = 1; index <= totalSupply; index++) {
        // const href = 'https://rinkeby.etherscan.io/address/'
        const tokenData = await digitizer?.getTokenData([index])
        const ownerOfToken = await digitizer?.ownerOf(index)
        const ownedByActiveUser = account == ownerOfToken ? true : false
        // const ownerAddressLinked = (
        //   <a href={href + ownerOfToken} target="_blank" rel="noreferrer">
        //     {ownerOfToken?.toString()?.substring(0, 6).concat('...').concat(ownerOfToken?.slice(-4))}
        //   </a>
        // )
        // const ownerAddressLinked = <button>I am a button</button>
        data.push({
          tokenId: index,
          //@ts-ignore
          UPB: toDecimal(tokenData?.current.UPB),
          // tokenId: tokensOfCurrentUser[index],
          // @ts-ignore
          UPBAtIssuance: toDecimal(tokenData.UPBAtIssuance),
          // @ts-ignore
          accumulatedCashFlow: toDecimal(tokenData.accumulatedCashFlow),
          // @ts-ignore
          channel: tokenData?.original.channel,
          // @ts-ignore
          noteRate: toDecimal(tokenData?.original.noteRate),
          // @ts-ignore
          creditScore: tokenData?.original.creditScore,
          // UPB: formatter.format(toDecimal(tokenData?.current.UPB)),
          // otherData: 'string',
          bestLP: 'string',
          bestLPPrice: 0,
          // currentOwner: ownerAddressLinked,
          currentOwner: ownerOfToken,
          ownerIsHomogenizer: false,
          isArbable: false,
          ownedByActiveAccount: ownedByActiveUser,
          UPBOfHomogenizerOwned: 0,
        })
      }
      setResult(data)
    })()
  }, [account, chainId, library])
  return result
}

export function useDigitizerUPB(tokenId: number) {
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState(() => 0)

  useEffect(() => {
    // @ts-ignore
    digitizer?.UPB(tokenId).then((UPB) => setResult(UPB?.toNumber()))
  }, [account, chainId, library])

  return result
}

export function useDigitizerOwnerOf(tokenId: number) {
  const { account, chainId, library } = useActiveWeb3React()
  const digitizer = useDigitizer()
  const [result, setResult] = useState(() => 0)
  digitizer?.ownerOf(tokenId).then((owner) => setResult(Number(owner)))

  // useEffect(() => {
  //   // @ts-ignore
  // }, [])

  return result
}
