//@ts-nocheck
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useAsyncDebounce } from 'react-table'

const Input = styled.input`
  max-width: 55px;
`

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  const [value, setValue] = useState(filterValue)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 500)
  return (
    <span>
      Filter: <br></br>
      <Input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </span>
  )
}
