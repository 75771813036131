import { decrement, increment } from '../../state/demos/counterActions'
import { useAppSelector, useAppDispatch } from '../../state/hooks'
import { ButtonPrimary } from 'components/Button'

export default function ReduxCounterExample() {
  // The `state` arg is correctly typed as `RootState` already
  const count = useAppSelector((state) => state.counter.value)
  const dispatch = useAppDispatch()

  return (
    <div>
      <div>
        <ButtonPrimary aria-label="Increment value" onClick={() => dispatch(increment())}>
          +
        </ButtonPrimary>
        <h3>{count}</h3>
        {/* <span></span> */}
        <ButtonPrimary aria-label="Decrement value" onClick={() => dispatch(decrement())}>
          -
        </ButtonPrimary>
      </div>
    </div>
  )
}
