/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-empty-function */
// @ts-nocheck
import styled from 'styled-components'
import { DarkGreyCard, PinkCard } from 'components/Card'
import { notEmpty } from 'utils'
import { useSavedTokens } from 'state/user/hooks'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useFetchedTokenDatas } from 'data/tokens/tokenData'
import { useAllTokenData, useTokenDatas } from 'state/tokens/hooks'
import { AutoColumn } from 'components/Column'
import { Link } from 'react-router-dom'
import { RowFixed } from 'components/Row'
import { Label, ClickableText } from 'components/Text'
import { ExtraSmallOnly, HideExtraSmall, TYPE } from 'theme'
import HoverInlineText from 'components/HoverInlineText'
import Percent from 'components/Percent'
import CurrencyLogo from 'components/CurrencyLogo'
import { PageButtons, Arrow, Break } from 'components/shared'
import useTheme from 'hooks/useTheme'
import { formatDollarAmount } from 'utils/numbers'
import { TOKEN_HIDE } from 'constants/index'
import { ButtonRadioChecked } from 'components/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { useDigitizer } from 'hooks/useContract'
import {
  useDigitizerTotalSupply,
  useDigitizerBalanceOfCurrentUser,
  useDigitizerTokensOfCurrentUser,
} from 'hooks/useDigitizer'
import { useActiveWeb3React } from 'hooks/web3'

const Wrapper = styled(PinkCard)`
  width: 100%;
  // text-align: center;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3fr repeat(4, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ResponsiveLogo = styled(CurrencyLogo)`
  @media screen and (max-width: 670px) {
    width: 16px;
    height: 16px;
  }
`

const DataRow = ({ tokenData, index }: { tokenData: TokenData; index: number }) => {
  const theme = useTheme()
  return (
    <LinkWrapper to={'tokens/' + tokenData.address}>
      <ResponsiveGrid>
        <Label>
          {/* {index + 1} */}
          {/* <ButtonRadioChecked active={true} /> */}
          <Checkbox
            indeterminate={false}
            checked={false}
            onChange={() => {}}
            inputProps={{ 'aria-label': 'select all desserts' }}
            style={{ textAlign: 'left' }}
          />
        </Label>
        <Label>
          <RowFixed>
            <ResponsiveLogo address={tokenData.address} />
          </RowFixed>
          <ExtraSmallOnly style={{ marginLeft: '6px' }}>
            <Label ml="8px">{tokenData.symbol}</Label>
          </ExtraSmallOnly>
          <HideExtraSmall style={{ marginLeft: '10px' }}>
            <RowFixed>
              <HoverInlineText text={tokenData.name} />
              <Label ml="8px" color={theme.text3}>
                ({tokenData.symbol})
              </Label>
            </RowFixed>
          </HideExtraSmall>
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.priceUSD)}
        </Label>
        <Label end={1} fontWeight={400}>
          <Percent value={tokenData.priceUSDChange} fontWeight={400} />
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.volumeUSD)}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.tvlUSD)}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  priceUSD: 'priceUSD',
  priceUSDChange: 'priceUSDChange',
  priceUSDChangeWeek: 'priceUSDChangeWeek',
}

export default function HelloWorld() {
  const allTokens = useAllTokenData()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allTokens])

  const sortedTokens = useMemo(() => {
    return formattedTokens
      ? formattedTokens
          .filter((x) => !!x && !TOKEN_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof TokenData] > b[sortField as keyof TokenData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(0, 50)
      : []
  }, [formattedTokens, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  const { account, chainId, library } = useActiveWeb3React()
  console.log('chain ID: ' + chainId)
  const digitizer = useDigitizer()
  const [nftBalance, setNftBalance] = useState(() => 0)
  // useEffect(async () => {
  //   const totalSupply = await digitizer?.totalSupply()
  //   // console.log(totalSupply?.toNumber())
  //   setNftBalance(totalSupply?.toNumber())
  // }, [account, chainId, library])

  const totalSupply = useDigitizerTotalSupply()
  const balanceOfCurrentUser = useDigitizerBalanceOfCurrentUser()
  const tokensOfCurrentUser = useDigitizerTokensOfCurrentUser()
  console.log(tokensOfCurrentUser)

  // const totalSupply = await digitizer?.totalSupply()
  // digitizer?.totalSupply().then((totalSupply) => setNftBalance(totalSupply?.toNumber()))

  // const asyncfunction = async () => {
  //   const totalSupply = digitizer?.totalSupply()
  //   setNftBalance(totalSupply?.toNumber())
  // }
  // asyncfunction()

  return (
    <Wrapper>
      Digitizer Address: {digitizer?.address}
      <br />
      {/* Digitizer NFT Balance: {nftBalance}
      <br /> */}
      There are {totalSupply} digitized loans in aggregate.
      <br />
      You own {balanceOfCurrentUser} digitized loans.
      <br />
      <br />
      Your inventory:
      <br />
      {Object.keys(tokensOfCurrentUser).map((token) => (
        <>
          {token}: {JSON.stringify(tokensOfCurrentUser[token])}
          <br />
        </>
      ))}
      <br />
      <h1>data pulled via /state/tokens/hooks</h1>
      <br />
      <ResponsiveGrid>
        <Label>#{/* <ButtonRadioChecked active={true} /> */}</Label>
        <ClickableText onClick={() => handleSort(SORT_FIELD.name)}>Name {arrow(SORT_FIELD.name)}</ClickableText>
        <ClickableText end={1} onClick={() => handleSort(SORT_FIELD.priceUSD)}>
          Price {arrow(SORT_FIELD.priceUSD)}
        </ClickableText>
        <ClickableText end={1} onClick={() => handleSort(SORT_FIELD.priceUSDChange)}>
          Price Change {arrow(SORT_FIELD.priceUSDChange)}
        </ClickableText>
        <ClickableText end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
          Volume 24H {arrow(SORT_FIELD.volumeUSD)}
        </ClickableText>
        <ClickableText end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
          TVL {arrow(SORT_FIELD.tvlUSD)}
        </ClickableText>
      </ResponsiveGrid>
      {sortedTokens.map((data, i) => {
        if (data) {
          return (
            <React.Fragment key={i}>
              <DataRow index={i} tokenData={data} />
              <Break />
            </React.Fragment>
          )
        }
        return null
      })}
    </Wrapper>
  )
}
