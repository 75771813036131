/* eslint-disable react/jsx-key */
import { Route, Switch, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Container from '@material-ui/core/Container'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useQuery } from '@apollo/client'
import { tokens } from '../../data/types/tokens'
import { TOKENS_BULK } from '../../data/tokenData'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default function DisplayPricesFromUniswapSubgraph(): any {
  const { loading, error, data } = useQuery<tokens>(TOKENS_BULK)
  const classes = useStyles()

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  console.log(data)

  if (data) {
    return (
      <Container fixed>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Symbol</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">TVL ($mm)</TableCell>
                {/* <TableCell align="center">Volume ($)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.tokens.map(({ symbol, totalValueLockedUSD, totalValueLocked }) => (
                <TableRow key={symbol}>
                  <TableCell align="center">{symbol}</TableCell>
                  <TableCell align="center">
                    {(totalValueLockedUSD / totalValueLocked).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    {(parseFloat(totalValueLockedUSD) / 1000000).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  {/* <TableCell align="center">
                    {parseFloat(volumeUSD).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    )
  } else {
    return <></>
  }
}
