import { gql } from '@apollo/client'

export const TOKENS_BULK = gql`
  query tokens {
    tokens(orderBy: totalValueLockedUSD, orderDirection: desc) {
      id
      symbol
      name
      derivedETH
      volumeUSD
      volume
      txCount
      totalValueLocked
      totalValueLockedUSD
    }
  }
`
