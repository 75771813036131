//@ts-nocheck
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { tableColumns } from 'components/MetaTable/dataStructure'
import { useMemo } from 'react'
import styled from 'styled-components'
import MetaTable from 'components/MetaTable'
import { useDigitizerCreateMetaTableData } from 'hooks/useDigitizer'

const Styles = styled.div`
  padding: 1rem;
  display: block;
  overflow: auto;

  table {
    border-spacing: 3px;
    border: 1px solid black;
    background: white;
    border: solid black 2px;
    border-radius: 10px;
    padding: 2px;

    thead {
      ${'' /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
    }
    tbody {
      ${'' /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
      height: 500px;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid black;
    }

    tr:hover {
      background-color: #686868;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:nth-child(even):hover {
      background-color: #686868;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        background: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }
    th {
      background-color: #3b444b;
      color: white;
    }

    tfoot tr,
    tfoot td {
      background-color: #3b444b;
      width: 100%;
      color: white;
      text-align: center;
    }
  }
`
export default function Metatable() {
  const columns = useMemo(() => tableColumns, [])
  const metaData = useDigitizerCreateMetaTableData()
  console.log(metaData)
  const hidden = []

  return metaData.length > 0 ? (
    <div>
      <Styles>
        <MetaTable hidden={hidden} columns={columns} data={metaData} />
      </Styles>
    </div>
  ) : (
    <div>...loading</div>
  )
}
