//@ts-nocheck
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import styled from 'styled-components'

const Styles = styled.div`
  margin: 10px;
`

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 500)
  return (
    <Styles>
      <span>
        Search all cells:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </Styles>
  )
}
