import { BigNumber, ethers } from 'ethers'
import { useEffect, useState } from 'react'
// import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core'
// import { Web3Provider as EthersWeb3Provider } from "@ethersproject/providers";
import { formatEther, parseEther } from '@ethersproject/units'
import { injected } from '../../connectors/index'

// Noah's code typically has connection objects in a separate connectors.ts file
// const injected = new InjectedConnector({
//   supportedChainIds: [1, 3, 4, 5, 42],
// });

const digitizerAddress: any = {
  4: '0xcab0eA55f4f7D93dB9d4a74cb0028dBB1A4aBF47',
}

function Account() {
  // This component is verbatim from https://github.com/NoahZinsmeister/web3-react/tree/v6/example
  const { account } = useWeb3React()

  return (
    <>
      <span>Account</span>
      <span role="img" aria-label="robot">
        🤖
      </span>
      <span>
        {account === null
          ? '-'
          : account
          ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
          : ''}
      </span>
    </>
  )
}

function Balance() {
  // This component is verbatim from https://github.com/NoahZinsmeister/web3-react/tree/v6/example
  const { account, library, chainId } = useWeb3React()

  const [balance, setBalance] = useState<BigNumber>()
  useEffect(() => {
    if (!!account && !!library) {
      let stale = false

      library
        .getBalance(account)
        .then((balance: any) => {
          if (!stale) {
            setBalance(balance)
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(BigNumber.from(0))
          }
        })

      return () => {
        stale = true
        setBalance(undefined)
      }
    } else {
      return () => false // pandichef; corrects typescript error
    }
  }, [account, library, chainId]) // ensures refresh if referential identity of library doesn't change across chainIds

  return (
    <>
      <span>Balance</span>
      <span role="img" aria-label="gold">
        💰
      </span>
      <span>{balance === null ? 'Error' : balance ? `Ξ${formatEther(balance)}` : ''}</span>
    </>
  )
}

export default function Web3ReactExample() {
  const [recipientAddress, setRecipientAddress] = useState('')
  const [quantityOfEthToSend, setQuantityOfEthToSend] = useState('')

  const context = useWeb3React()
  const { connector, library, chainId, account, activate, deactivate, active, error } = context

  console.log('contextObject: ', context)
  console.log('account: ', account)
  console.log('library: ', library)
  console.log('library.getSigner: ', library ? library.getSigner(account) : 'Nothing hooked up yet')
  console.log('recipientAddress: ', recipientAddress)
  console.log('recipientAddress: ', typeof recipientAddress)
  console.log('quantityOfEthToSend: ', quantityOfEthToSend)
  console.log('quantityOfEthToSend: ', typeof quantityOfEthToSend)

  console.log(typeof chainId)

  return (
    <div>
      Digitizer Address: {chainId ? digitizerAddress[chainId] : ''}
      Digitizer Supply: {}
      <br />(<i>Note: This won&apos;t do anything if MetaMask isn&apos;t activated</i>)
      <br />
      <button
        onClick={() => {
          activate(injected)
        }}
      >
        Activate
      </button>
      <br />
      <button
        onClick={() => {
          deactivate()
        }}
      >
        Deactivate
      </button>
      <br />
      <Account />
      <br />
      <Balance />
      <br />
      {!!(library && account) && (
        <button
          onClick={() => {
            library
              .getSigner(account)
              .signMessage('👋')
              .then((signature: any) => {
                window.alert(`Success!\n\n${signature}`)
              })
              .catch((error: { message: any }) => {
                window.alert('Failure!' + (error && error.message ? `\n\n${error.message}` : ''))
              })
          }}
        >
          Sign Message
        </button>
      )}
      <br />
      <br />
      <br />
      <br />
      {!!(library && account) && (
        <>
          Recipient Address:{' '}
          <input
            type="text"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value.toString())}
          />
          <br />
          Quantity Of ETH To Send:{' '}
          <input
            type="text"
            value={quantityOfEthToSend}
            onChange={(e) => setQuantityOfEthToSend(e.target.value.toString())}
          />
          <br />
          {/* sendTransaction method comes from ethersjs */}
          <button
            onClick={() => {
              library
                .getSigner(account)
                .sendTransaction({
                  to: recipientAddress,
                  value: parseEther(quantityOfEthToSend),
                })
                .then((signature: any) => {
                  window.alert(`Success!\n\nYou just sent ${quantityOfEthToSend} ETH to ${recipientAddress}.`)
                })
                .catch((error: { message: any }) => {
                  window.alert('Failure!' + (error && error.message ? `\n\n${error.message}` : ''))
                })
            }}
          >
            Send Ether
          </button>
        </>
      )}
    </div>
  )
}
